<nav class="navbar navbar-dark navbar-expand-lg " *ngIf="isUserLogged()">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">Prontuário Eletrônico</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li *ngIf="hasClaim('pacientes:list')" class="nav-item">
                    <a class="nav-link" [routerLink]="['/pacientes']" [routerLinkActive]="['active']">Pacientes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/agendamentos']" [routerLinkActive]="['active']">Agendamentos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/salas']" [routerLinkActive]="['active']">Salas</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Financeiro
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li *ngIf="hasClaim('Financeiro')"><a class="dropdown-item" [routerLink]="['/financeiro/faturamentos']" [routerLinkActive]="['active']">Faturamentos</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/financeiro/relatorios']" [routerLinkActive]="['active']">Relatórios</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/financeiro/valores']" [routerLinkActive]="['active']">Valores</a></li>
                        <!-- <li>
                            <hr class="dropdown-divider">
                        </li> -->
                    </ul>
                </li>
                <li class="nav-item" *ngIf="hasClaim('Secretaria')">
                    <a class="nav-link" [routerLink]="['/salas']" [routerLinkActive]="['active']">Salas</a>
                </li>
            </ul>
            <form class="d-flex w-100 d-inline mx-4">
                <input class="form-control me-2" type="search" placeholder="Nome do paciente" aria-label="Search">
                <button class="btn btn-light" type="submit">Buscar</button>
            </form>
            <button class="btn btn-danger" (click)="logout()">Sair</button>
        </div>
    </div>
</nav>